import _qrcode from "./qrcode";
import _awesomeQr from "./awesome-qr";
var exports = {};

var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !exports.hasOwnProperty(p)) __createBinding(exports, m, p);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

__exportStar(_qrcode, exports);

var awesome_qr_1 = _awesomeQr;
Object.defineProperty(exports, "AwesomeQR", {
  enumerable: true,
  get: function () {
    return awesome_qr_1.AwesomeQR;
  }
});
export default exports;
export const __esModule = exports.__esModule,
      AwesomeQR = exports.AwesomeQR,
      QRMath = exports.QRMath,
      QRUtil = exports.QRUtil,
      QRMaskPattern = exports.QRMaskPattern,
      QRErrorCorrectLevel = exports.QRErrorCorrectLevel,
      QRCodeModel = exports.QRCodeModel;